.poll-content {
    border: 0;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    border-radius: 5px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    height: 100%;
}
.poll-content:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: transparent;
}
.poll-content.poll-content-type-1:before {
    background-color: #8146FF;
}
.poll-content .poll-title {
    font-size: 24px;
    line-height: 30px;
}
.poll-content .poll-label {
    background-color: #8146FF;
    color: #fff;
    padding: 8px 30px;
    position: absolute;
    top: 0;
    right: 0;   
    font-size: 16px;
    line-height: 20px;
}
.poll-content .poll-content-options {
    padding: 60px 0px;
}
.poll-content .poll-content-options .form-check-label {
    font-size: 36px;
    line-height: 44px;
    color: #000;
    font-weight: 700;
}
.poll-content p {
    color: #7983A6;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}
.poll-option {
    margin-bottom: 30px;
}
.poll-option label {
    font-weight: 700;
    color: #000;
}
.poll-option .progress{
    height: 44px;
    border-radius: 50px;
    background-color: #F7F7F7;
    font-size: 18px;
    font-weight: 700;
}
.poll-option.poll-option-1 .progress .progress-bar {
    background-color: #8146FF;
}
.poll-option.poll-option-2 .progress .progress-bar {
    background-color: #FF4689;
}
.poll-option.poll-option-3 .progress .progress-bar {
    background-color: #FF9446;
}
.poll-option.poll-option-4 .progress .progress-bar {
    background-color: #FC4747;
}
.poll-option.poll-option-5 .progress .progress-bar {
    background-color: #17ACFF;
}
.poll-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.poll-actions .poll-action {
    width: 50px;
    height: 50px;
    border: 2px solid #000;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    transition: all ease-in-out 300ms;
}
.poll-actions .poll-action.poll-like {
    border-color: #8146FF;
}
.poll-actions .poll-action.poll-like:hover {
    background-color: #8146FF;
}
.poll-actions .poll-action.poll-like:hover svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-like p {
    color: #8146FF;
}

.poll-actions .poll-action.poll-like-selected {
    background-color: #8146FF;
    border-color: #8146FF;
}
.poll-actions .poll-action.poll-like-selected svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-like-selected:hover {
    background-color: #fff;
}
.poll-actions .poll-action.poll-like-selected:hover svg path {
    fill: #8146FF;
}
.poll-actions .poll-action.poll-dislike {
    border-color: #FF4689;
}
.poll-actions .poll-action.poll-dislike:hover {
    background-color: #FF4689;
}
.poll-actions .poll-action.poll-dislike:hover svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-dislike p {
    color: #FF4689;
}

.poll-actions .poll-action.poll-dislike-selected {
    background-color: #FF4689;
    border-color: #FF4689;
}
.poll-actions .poll-action.poll-dislike-selected svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-dislike-selected:hover {
    background-color: #fff;
}
.poll-actions .poll-action.poll-dislike-selected:hover svg path {
    fill: #FF4689;
}

.poll-actions .poll-action.poll-report {
    border-color: #7983A6;
}
.poll-actions .poll-action.poll-report:hover {
    background-color: #7983A6;
}
.poll-actions .poll-action.poll-report:hover svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-report p {
    color: #7983A6;
}

.poll-actions .poll-action.poll-report-selected {
    background-color: #7983A6;
    border-color: #7983A6;
}

.poll-actions .poll-action.poll-report-selected svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-report-selected:hover {
    background-color: #fff;
}
.poll-actions .poll-action.poll-report-selected:hover svg path {
    fill: #7983A6;
}

.poll-actions .poll-action p {
    font-size: 14px;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 7px;
    text-align: center;
}

/* Poll Size Col 4 */
.poll-size-col-4 .poll-option .progress {
    height: 22px;
    font-size: 14px;
}