.content-wrapper {
    padding: 40px 0px;
}
.total-poll {
    margin-bottom: 20px;
}
.total-poll h4 {
    font-size: 22px;
    line-height: 30px;
    color: #000;
    text-align: right;
    font-weight: 700;
}
.total-poll h4 span {
    color: #FF4689;
}
.poll-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
}
.poll-category.poll-type-1:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #8146FF;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-2:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #FF4689;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-3:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #FF9446;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-4:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #FC4747;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-5:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #17ACFF;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category h5 {
    font-size: 26px;
    line-height: 34px;
}
.poll-category a {
    color: #7983A6;
    font-size: 18px;
    line-height: 22px;
}
.poll-category a svg {
    margin-left: 5px;
}
@media only screen and (max-width: 991px) {
    .content-wrapper {
        padding: 30px 0px;
    }
    .total-poll h4 {
        text-align: center;
    }
    .poll-category {
        margin-top: 0;
    }
    .poll-category h5 {
        font-size: 18px;
    }
    .poll-content .poll-content-options {
        padding-top: 0;
        padding-bottom: 30px;
    }
    .poll-content .poll-content-options .form-check-label {
        font-size: 20px;
    }
    .poll-content-wrapper .col-lg-8, .poll-content-wrapper .col-lg-4, .poll-content-wrapper .col-lg-6, .poll-content-wrapper .col-lg-4 {
        margin-bottom: 30px;
    }
}