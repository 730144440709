.contact-us-wrapper .contact-info .info {
    text-align: center;
    margin-bottom: 30px;
}
.contact-us-wrapper .contact-info img {
    max-width: 100%;
}
.contact-us-wrapper .contact-info .info p {
    text-align: center;
    font-weight: 700;
    color: #8146FF;
}
.contact-us-wrapper .contact-form label {
    font-weight: 700;
    color: #000;
}
.contact-us-wrapper .contact-form .form-group {
    position: relative;
}
.contact-us-wrapper .contact-form .form-group .icon {
    position: absolute;
    left: 25px;
    top: 35px;
}
.contact-us-wrapper .contact-form .form-group .form-control {
    padding-left: 42px;
}
@media only screen and (max-width: 991px) {
    .contact-us-wrapper .contact-info {
        margin-bottom: 30px;
    }
    .contact-us-wrapper .contact-form .col-sm-6:first-child {
        margin-bottom: 1rem;
    }
 }