.footer-wrapper {
    padding:30px 0px;
    background-color: #8146FF;
}
.footer-wrapper .copyright p{
    margin: 0;
    color: #fff;
    font-weight: 700;
}
.footer-wrapper .nav-item a {
    margin: 0;
    color: #fff;
    font-weight: 700;
    padding:  0 20px;
    position: relative;
    transition: all ease-in 200ms;
    font-size: 18px;
}
.footer-wrapper .nav-item a:hover{
    color: #FF4689;
}
@media only screen and (max-width: 991px) {
    .footer-wrapper .copyright {
        margin-bottom: 15px;
    }
    .footer-wrapper .copyright p{
        text-align: center;
    }
    .footer-wrapper .nav {
        justify-content: center;
    }
}