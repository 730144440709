@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap');

body {
    font-family: 'Sen', sans-serif;
    font-size: 16px;
    line-height: 1;
    background-color: #F7F7F7;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 700;
}
p {
    margin: 0;
    font-size: 18px;
    line-height: 26px;
    color: #7983A6;
    text-align: justify;
}
a, a:hover {
    text-decoration: none;
}
.highlight {
    color: #8146FF;
}
.btn {
    padding: 13px 20px;
    background-color: #8146FF;
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    border: 0;
}
.btn svg {
    margin-right: 5px;
}
.btn:hover, .btn:focus, .btn:active, .btn:not(:disabled):not(.disabled) {
    background-color: #FF4689 !important;
    box-shadow: none !important;
}
.card {
    border: 0;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    border-radius: 5px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    height: 100%;
}
.card .card-body {
    padding: 0;
}
.card .card-body .card-title {
    font-size: 30px;
    line-height: 38px;
    color: #000;
    margin-bottom: 30px;
}
.card .card-body .card-title span {
    color: #FF4689;
}
.form-group .col-form-label {
    font-size: 21px;
    line-height: 26px;
    color: #000;
    font-weight: 700;
}
.form-group .form-control {
    height: 44px;
    border: 2px solid #FF4689;
    font-weight: 700;
}
.form-group .form-control:hover, .form-group .form-control:focus {
    box-shadow: none;
}
.form-group .form-control:focus {
    border-color: #8146FF;
}
.form-group textarea.form-control {
    height: auto;
}
.form-group small {
    font-size: 16px;
    line-height: 22px;
    color: #7983A6;
    font-weight: 700;
    margin-top: 5px;
    display: block;
}
.mb-30 {
    margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
.card .card-body .card-title {
    font-size: 18px;
    line-height: 26px;
}
}