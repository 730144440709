header {
    padding: 14px 0px;
}
.toggle-button, .navigation-toggle {
    display: none;
}
.search-wrapper {
    position: relative;
    margin-top: -25px;
}
.search-wrapper .form-group {
    margin: 0;
}
.search-wrapper .search-icon{
    position: relative  ;
    left: 10px;
    top: 30px;
}
.search-wrapper .form-control {
    width: 266px;
    height: 40px;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    padding-left: 40px;
}
.search-mobile {
    display: none;
}
@media only screen and (max-width: 991px) {
    header {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    }
    header .search-wrapper {
        display: none;
    }
    header .navigation-wrapper {
        display: none;
    }
    header .container {
        justify-content: space-between !important;
    }
    header .toggle-button {
        display: flex;
    }
    header .toggle-button .category-toggle {
        margin-right: 20px;
    }
    header .logo-wrapper {
        margin-left: -20px;
    }
    header .navigation-toggle {
        display: block;
    }
    header .category-toggle, header .search-icon, header .navigation-toggle {
        cursor: pointer;
    }
    .desktop {
        display: none;
    }
    .polls-menu-wrapper {
        position: absolute;
        width: 414px;
        height: 147px;
        left: -15px;
        top: 58px;
        background-color: #FF4689;
        z-index: 1;
    }

    .polls-menu-wrapper ul {
        margin-top: 0;
        list-style: none;
    }

    .polls-menu-wrapper ul li{
        padding-top: 17px;
    }

    .polls-menu-wrapper a {
        font-weight: bold;
        font-size: 18px;
        color: #fff;
    }
  }
  @media only screen and (max-width: 420px) {
    header .logo-wrapper {
        margin-left: 70px;
    }
    header .logo-wrapper img {
        max-height: 30px;
    }
    header .toggle-button .category-toggle {
        margin-right: 10px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .menu-mobile {
        width: 100%;
        margin-top: -20px;
    }

    .menu-search{
        display: flex;
        width: 100%;
    }

    .showMenu {
        width: 10%;
        /* border-style: solid; */
    }

    .menu-svg {
        background-color: #EEEEEE;
        width: 35px;
        height: 35px;
    }
        
    .search-mobile {
        display: flex;
        width: 25%; 
        /* padding-bottom: 1%; */
        margin-left: 35px;
        position: absolute;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.25);   
        border: 0px
    }

    .search-mobile .form-control{
        border: 0px
    }

    .search-box-mobile {
        margin-left: 5px;
        width: 90%;
    }

    .categoryList {
        margin-top: 20px;
        position: absolute;
        z-index: 1;
    }

}