.categories-wrapper {
    padding:18px 0px;
    background-color: #8146FF;
}
.categories-wrapper .nav-item a {
    margin: 0;
    color: #fff;
    font-weight: 700;
    padding: 0 0.3rem;
    position: relative;
    transition: all ease-in 500ms;
    font-size: 18px;
}
.categories-wrapper .nav-item a:hover:before {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 0;
}

.categories-wrapper .categories-label {
    display: none;
}

@media only screen and (max-width: 991px) {
    .categories-wrapper .categories-label {
        display: block;
        font-weight: 700;
        color: #fff;
        padding: 0 0.3rem;
        font-size: 18px;
        margin-bottom: 35px;
    }

    .categories-wrapper {
        padding:20px 0px;
        margin-left: -15px;
        background-color: #8146FF;
        width: 414px;
        height: 1294px;
        left: 0px;
        top: 0px;
    }

    .categories-wrapper .nav-item a {
        margin: 0;
        color: #fff;
        font-weight: 700;
        padding: 0 0.3rem;
        position: relative;
        transition: all ease-in 500ms;
        font-size: 18px;
    }

    .categories-wrapper .nav-item {
        padding-left: 20px;
        padding-bottom: 15px;
    }

    .categories-wrapper .nav {
        display: block;
    }
}