.privacy-policy p{
    margin-bottom: 10px;
}
.privacy-policy h5 {
    margin-bottom: 15px;
    color: #FF4689;
    margin-top: 15px;
}
.privacy-policy b {
    margin-bottom: 10px;
    display: block;
}
.privacy-policy a {
    color: #8146FF;
    font-weight: 700;
}
.privacy-policy ul {
    padding: 0;
    list-style-type:square;
    padding-left: 20px;
}
.privacy-policy ul li {
    line-height: 24px;
    color: #7983A6;   
}
.privacy-policy ul li b {
    display: inline-block;
    margin: 0;
    color: #8146FF;
}