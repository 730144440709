.add-poll .form-group .icon {
    position: absolute;
    left: 25px;
    top: 11px;
}
.add-poll .form-group .icon + .form-control {
    padding-left: 40px;
}
.add-poll .form-group {
    margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
    .add-poll .form-group {
        margin-bottom: 15px;
    }
}