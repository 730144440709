.about-us .about-faq {
    margin-bottom: 30px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    background-color: #fff;
    border-radius: 5px;
}
.about-us .about-faq h4{
    padding: 13px 20px;
    border-radius: 5px;
    margin: 0;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    font-size: 18px;
    line-height: 26px;
    color: #333;
    transition: all ease-in-out 300ms;
}
.about-us .about-faq h4:hover {
    background-color: #8146FF;
    color: #fff;
}
.about-us .about-faq h4 + div {
    padding: 20px;
}
@media only screen and (max-width: 991px) {
    .about-info {
        margin-bottom: 30px;
    }
    .about-info img {
        max-width: 100%;
    }
}