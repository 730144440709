@import url(https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap);
body {
    font-family: 'Sen', sans-serif;
    font-size: 16px;
    line-height: 1;
    background-color: #F7F7F7;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 700;
}
p {
    margin: 0;
    font-size: 18px;
    line-height: 26px;
    color: #7983A6;
    text-align: justify;
}
a, a:hover {
    text-decoration: none;
}
.highlight {
    color: #8146FF;
}
.btn {
    padding: 13px 20px;
    background-color: #8146FF;
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    border: 0;
}
.btn svg {
    margin-right: 5px;
}
.btn:hover, .btn:focus, .btn:active, .btn:not(:disabled):not(.disabled) {
    background-color: #FF4689 !important;
    box-shadow: none !important;
}
.card {
    border: 0;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    border-radius: 5px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    height: 100%;
}
.card .card-body {
    padding: 0;
}
.card .card-body .card-title {
    font-size: 30px;
    line-height: 38px;
    color: #000;
    margin-bottom: 30px;
}
.card .card-body .card-title span {
    color: #FF4689;
}
.form-group .col-form-label {
    font-size: 21px;
    line-height: 26px;
    color: #000;
    font-weight: 700;
}
.form-group .form-control {
    height: 44px;
    border: 2px solid #FF4689;
    font-weight: 700;
}
.form-group .form-control:hover, .form-group .form-control:focus {
    box-shadow: none;
}
.form-group .form-control:focus {
    border-color: #8146FF;
}
.form-group textarea.form-control {
    height: auto;
}
.form-group small {
    font-size: 16px;
    line-height: 22px;
    color: #7983A6;
    font-weight: 700;
    margin-top: 5px;
    display: block;
}
.mb-30 {
    margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
.card .card-body .card-title {
    font-size: 18px;
    line-height: 26px;
}
}

.footer-wrapper {
    padding:30px 0px;
    background-color: #8146FF;
}
.footer-wrapper .copyright p{
    margin: 0;
    color: #fff;
    font-weight: 700;
}
.footer-wrapper .nav-item a {
    margin: 0;
    color: #fff;
    font-weight: 700;
    padding:  0 20px;
    position: relative;
    transition: all ease-in 200ms;
    font-size: 18px;
}
.footer-wrapper .nav-item a:hover{
    color: #FF4689;
}
@media only screen and (max-width: 991px) {
    .footer-wrapper .copyright {
        margin-bottom: 15px;
    }
    .footer-wrapper .copyright p{
        text-align: center;
    }
    .footer-wrapper .nav {
        justify-content: center;
    }
}
.navigation-wrapper .nav .nav-item a {
    padding: 0 14px;
    color: #7983A6;
    font-weight: 700;
    transition: all ease-in 200ms;
}
.navigation-wrapper .nav .nav-item a:hover {
    color: #FF4689;
}
.navigation-wrapper .nav .nav-item a img{
    margin-top: -2px;
}
.categories-wrapper {
    padding:18px 0px;
    background-color: #8146FF;
}
.categories-wrapper .nav-item a {
    margin: 0;
    color: #fff;
    font-weight: 700;
    padding: 0 0.3rem;
    position: relative;
    transition: all ease-in 500ms;
    font-size: 18px;
}
.categories-wrapper .nav-item a:hover:before {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 0;
}

.categories-wrapper .categories-label {
    display: none;
}

@media only screen and (max-width: 991px) {
    .categories-wrapper .categories-label {
        display: block;
        font-weight: 700;
        color: #fff;
        padding: 0 0.3rem;
        font-size: 18px;
        margin-bottom: 35px;
    }

    .categories-wrapper {
        padding:20px 0px;
        margin-left: -15px;
        background-color: #8146FF;
        width: 414px;
        height: 1294px;
        left: 0px;
        top: 0px;
    }

    .categories-wrapper .nav-item a {
        margin: 0;
        color: #fff;
        font-weight: 700;
        padding: 0 0.3rem;
        position: relative;
        transition: all ease-in 500ms;
        font-size: 18px;
    }

    .categories-wrapper .nav-item {
        padding-left: 20px;
        padding-bottom: 15px;
    }

    .categories-wrapper .nav {
        display: block;
    }
}
header {
    padding: 14px 0px;
}
.toggle-button, .navigation-toggle {
    display: none;
}
.search-wrapper {
    position: relative;
    margin-top: -25px;
}
.search-wrapper .form-group {
    margin: 0;
}
.search-wrapper .search-icon{
    position: relative  ;
    left: 10px;
    top: 30px;
}
.search-wrapper .form-control {
    width: 266px;
    height: 40px;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    padding-left: 40px;
}
.search-mobile {
    display: none;
}
@media only screen and (max-width: 991px) {
    header {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    }
    header .search-wrapper {
        display: none;
    }
    header .navigation-wrapper {
        display: none;
    }
    header .container {
        justify-content: space-between !important;
    }
    header .toggle-button {
        display: flex;
    }
    header .toggle-button .category-toggle {
        margin-right: 20px;
    }
    header .logo-wrapper {
        margin-left: -20px;
    }
    header .navigation-toggle {
        display: block;
    }
    header .category-toggle, header .search-icon, header .navigation-toggle {
        cursor: pointer;
    }
    .desktop {
        display: none;
    }
    .polls-menu-wrapper {
        position: absolute;
        width: 414px;
        height: 147px;
        left: -15px;
        top: 58px;
        background-color: #FF4689;
        z-index: 1;
    }

    .polls-menu-wrapper ul {
        margin-top: 0;
        list-style: none;
    }

    .polls-menu-wrapper ul li{
        padding-top: 17px;
    }

    .polls-menu-wrapper a {
        font-weight: bold;
        font-size: 18px;
        color: #fff;
    }
  }
  @media only screen and (max-width: 420px) {
    header .logo-wrapper {
        margin-left: 70px;
    }
    header .logo-wrapper img {
        max-height: 30px;
    }
    header .toggle-button .category-toggle {
        margin-right: 10px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .menu-mobile {
        width: 100%;
        margin-top: -20px;
    }

    .menu-search{
        display: flex;
        width: 100%;
    }

    .showMenu {
        width: 10%;
        /* border-style: solid; */
    }

    .menu-svg {
        background-color: #EEEEEE;
        width: 35px;
        height: 35px;
    }
        
    .search-mobile {
        display: flex;
        width: 25%; 
        /* padding-bottom: 1%; */
        margin-left: 35px;
        position: absolute;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.25);   
        border: 0px
    }

    .search-mobile .form-control{
        border: 0px
    }

    .search-box-mobile {
        margin-left: 5px;
        width: 90%;
    }

    .categoryList {
        margin-top: 20px;
        position: absolute;
        z-index: 1;
    }

}
.poll-content {
    border: 0;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    border-radius: 5px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    height: 100%;
}
.poll-content:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: transparent;
}
.poll-content.poll-content-type-1:before {
    background-color: #8146FF;
}
.poll-content .poll-title {
    font-size: 24px;
    line-height: 30px;
}
.poll-content .poll-label {
    background-color: #8146FF;
    color: #fff;
    padding: 8px 30px;
    position: absolute;
    top: 0;
    right: 0;   
    font-size: 16px;
    line-height: 20px;
}
.poll-content .poll-content-options {
    padding: 60px 0px;
}
.poll-content .poll-content-options .form-check-label {
    font-size: 36px;
    line-height: 44px;
    color: #000;
    font-weight: 700;
}
.poll-content p {
    color: #7983A6;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}
.poll-option {
    margin-bottom: 30px;
}
.poll-option label {
    font-weight: 700;
    color: #000;
}
.poll-option .progress{
    height: 44px;
    border-radius: 50px;
    background-color: #F7F7F7;
    font-size: 18px;
    font-weight: 700;
}
.poll-option.poll-option-1 .progress .progress-bar {
    background-color: #8146FF;
}
.poll-option.poll-option-2 .progress .progress-bar {
    background-color: #FF4689;
}
.poll-option.poll-option-3 .progress .progress-bar {
    background-color: #FF9446;
}
.poll-option.poll-option-4 .progress .progress-bar {
    background-color: #FC4747;
}
.poll-option.poll-option-5 .progress .progress-bar {
    background-color: #17ACFF;
}
.poll-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.poll-actions .poll-action {
    width: 50px;
    height: 50px;
    border: 2px solid #000;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    transition: all ease-in-out 300ms;
}
.poll-actions .poll-action.poll-like {
    border-color: #8146FF;
}
.poll-actions .poll-action.poll-like:hover {
    background-color: #8146FF;
}
.poll-actions .poll-action.poll-like:hover svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-like p {
    color: #8146FF;
}

.poll-actions .poll-action.poll-like-selected {
    background-color: #8146FF;
    border-color: #8146FF;
}
.poll-actions .poll-action.poll-like-selected svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-like-selected:hover {
    background-color: #fff;
}
.poll-actions .poll-action.poll-like-selected:hover svg path {
    fill: #8146FF;
}
.poll-actions .poll-action.poll-dislike {
    border-color: #FF4689;
}
.poll-actions .poll-action.poll-dislike:hover {
    background-color: #FF4689;
}
.poll-actions .poll-action.poll-dislike:hover svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-dislike p {
    color: #FF4689;
}

.poll-actions .poll-action.poll-dislike-selected {
    background-color: #FF4689;
    border-color: #FF4689;
}
.poll-actions .poll-action.poll-dislike-selected svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-dislike-selected:hover {
    background-color: #fff;
}
.poll-actions .poll-action.poll-dislike-selected:hover svg path {
    fill: #FF4689;
}

.poll-actions .poll-action.poll-report {
    border-color: #7983A6;
}
.poll-actions .poll-action.poll-report:hover {
    background-color: #7983A6;
}
.poll-actions .poll-action.poll-report:hover svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-report p {
    color: #7983A6;
}

.poll-actions .poll-action.poll-report-selected {
    background-color: #7983A6;
    border-color: #7983A6;
}

.poll-actions .poll-action.poll-report-selected svg path {
    fill: #fff;
}
.poll-actions .poll-action.poll-report-selected:hover {
    background-color: #fff;
}
.poll-actions .poll-action.poll-report-selected:hover svg path {
    fill: #7983A6;
}

.poll-actions .poll-action p {
    font-size: 14px;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 7px;
    text-align: center;
}

/* Poll Size Col 4 */
.poll-size-col-4 .poll-option .progress {
    height: 22px;
    font-size: 14px;
}
.content-wrapper {
    padding: 40px 0px;
}
.total-poll {
    margin-bottom: 20px;
}
.total-poll h4 {
    font-size: 22px;
    line-height: 30px;
    color: #000;
    text-align: right;
    font-weight: 700;
}
.total-poll h4 span {
    color: #FF4689;
}
.poll-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
}
.poll-category.poll-type-1:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #8146FF;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-2:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #FF4689;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-3:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #FF9446;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-4:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #FC4747;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category.poll-type-5:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #17ACFF;
    height: 32px;
    left: 0;
    top: 0;
}
.poll-category h5 {
    font-size: 26px;
    line-height: 34px;
}
.poll-category a {
    color: #7983A6;
    font-size: 18px;
    line-height: 22px;
}
.poll-category a svg {
    margin-left: 5px;
}
@media only screen and (max-width: 991px) {
    .content-wrapper {
        padding: 30px 0px;
    }
    .total-poll h4 {
        text-align: center;
    }
    .poll-category {
        margin-top: 0;
    }
    .poll-category h5 {
        font-size: 18px;
    }
    .poll-content .poll-content-options {
        padding-top: 0;
        padding-bottom: 30px;
    }
    .poll-content .poll-content-options .form-check-label {
        font-size: 20px;
    }
    .poll-content-wrapper .col-lg-8, .poll-content-wrapper .col-lg-4, .poll-content-wrapper .col-lg-6, .poll-content-wrapper .col-lg-4 {
        margin-bottom: 30px;
    }
}
.poll-content.poll-content-type-2:before {
    background-color: #FF4689;
}
.poll-content.poll-content-type-3:before {
    background-color: #FF9446;
}
.poll-content.poll-content-type-4:before {
    background-color: #FC4747;
}
.about-us .about-faq {
    margin-bottom: 30px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    background-color: #fff;
    border-radius: 5px;
}
.about-us .about-faq h4{
    padding: 13px 20px;
    border-radius: 5px;
    margin: 0;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
    font-size: 18px;
    line-height: 26px;
    color: #333;
    transition: all ease-in-out 300ms;
}
.about-us .about-faq h4:hover {
    background-color: #8146FF;
    color: #fff;
}
.about-us .about-faq h4 + div {
    padding: 20px;
}
@media only screen and (max-width: 991px) {
    .about-info {
        margin-bottom: 30px;
    }
    .about-info img {
        max-width: 100%;
    }
}
.add-poll .form-group .icon {
    position: absolute;
    left: 25px;
    top: 11px;
}
.add-poll .form-group .icon + .form-control {
    padding-left: 40px;
}
.add-poll .form-group {
    margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
    .add-poll .form-group {
        margin-bottom: 15px;
    }
}
.contact-us-wrapper .contact-info .info {
    text-align: center;
    margin-bottom: 30px;
}
.contact-us-wrapper .contact-info img {
    max-width: 100%;
}
.contact-us-wrapper .contact-info .info p {
    text-align: center;
    font-weight: 700;
    color: #8146FF;
}
.contact-us-wrapper .contact-form label {
    font-weight: 700;
    color: #000;
}
.contact-us-wrapper .contact-form .form-group {
    position: relative;
}
.contact-us-wrapper .contact-form .form-group .icon {
    position: absolute;
    left: 25px;
    top: 35px;
}
.contact-us-wrapper .contact-form .form-group .form-control {
    padding-left: 42px;
}
@media only screen and (max-width: 991px) {
    .contact-us-wrapper .contact-info {
        margin-bottom: 30px;
    }
    .contact-us-wrapper .contact-form .col-sm-6:first-child {
        margin-bottom: 1rem;
    }
 }
.privacy-policy p{
    margin-bottom: 10px;
}
.privacy-policy h5 {
    margin-bottom: 15px;
    color: #FF4689;
    margin-top: 15px;
}
.privacy-policy b {
    margin-bottom: 10px;
    display: block;
}
.privacy-policy a {
    color: #8146FF;
    font-weight: 700;
}
.privacy-policy ul {
    padding: 0;
    list-style-type:square;
    padding-left: 20px;
}
.privacy-policy ul li {
    line-height: 24px;
    color: #7983A6;   
}
.privacy-policy ul li b {
    display: inline-block;
    margin: 0;
    color: #8146FF;
}
.terms-and-condition p{
    margin-bottom: 10px;
}
.terms-and-condition h5 {
    margin-bottom: 15px;
    color: #FF4689;
    margin-top: 15px;
}
.terms-and-condition b {
    margin-bottom: 10px;
    display: block;
}
.terms-and-condition a {
    color: #8146FF;
    font-weight: 700;
}
.terms-and-condition ul {
    padding: 0;
    list-style-type:square;
    padding-left: 20px;
}
.terms-and-condition ul li {
    line-height: 24px;
    color: #7983A6;
    
}
