.terms-and-condition p{
    margin-bottom: 10px;
}
.terms-and-condition h5 {
    margin-bottom: 15px;
    color: #FF4689;
    margin-top: 15px;
}
.terms-and-condition b {
    margin-bottom: 10px;
    display: block;
}
.terms-and-condition a {
    color: #8146FF;
    font-weight: 700;
}
.terms-and-condition ul {
    padding: 0;
    list-style-type:square;
    padding-left: 20px;
}
.terms-and-condition ul li {
    line-height: 24px;
    color: #7983A6;
    
}