.navigation-wrapper .nav .nav-item a {
    padding: 0 14px;
    color: #7983A6;
    font-weight: 700;
    transition: all ease-in 200ms;
}
.navigation-wrapper .nav .nav-item a:hover {
    color: #FF4689;
}
.navigation-wrapper .nav .nav-item a img{
    margin-top: -2px;
}